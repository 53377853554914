import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.modules.list');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-modules-list',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.modules.list/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn-role {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.detail-modal .th {
  width: 150px !important;
  text-transform: none !important;
  overflow-wrap: anywhere;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.w-35 {
  width: 35% !important;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-left {
  justify-self: left;
}`],
})
export class PortalKeycloudAdminModulesListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        text: '',
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load() {
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        this.list = data;
        this.loaded = true;
        await this.service.render();
    }

    public add = {
        show: false,
        mode: null,
        data: {},
        toggle: (mode = null) => {
            this.add.data = {
                namespace: "",
            };
            if (mode) this.add.mode = mode;
            this.add.show = !this.add.show;
            this.service.render();
        },
        save: async () => {
            const body = this.service.copy(this.add.data);
            const { namespace } = body;
            if (!namespace) return await this.service.error("NAMESPACE를 입력해주세요.");
            if (!/^[a-z0-9]+$/.test(namespace)) return await this.service.error("NAMESPACE는 영어 소문자와 숫자만 허용됩니다.");
            if (namespace.length > 64) return await this.service.error("NAMESPACE는 64자 이하로 설정해주세요.");

            await this.service.loading.show();
            const { code, data } = await wiz.call("save", body);
            await this.service.loading.hide();
            if (code !== 200) return await this.service.error(data);
            await this.service.success("모듈 추가 성공!");
            this.add.toggle();
            this.service.href(`/admin/modules/${namespace}`);
        },
    };

    public required = ["consent", "discovery"];

    public async remove(item) {
        if (this.required.includes(item)) return await this.service.error("중요 모듈은 삭제할 수 없습니다.");
        const res = await this.service.alert.show({
            title: `모듈 삭제`,
            status: "error",
            message: `정말 "${item}" 모듈을 삭제하시겠습니까?`,
            action: "삭제",
            actionBtn: "error",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("remove", { namespace: item });
        if (code !== 200) return await this.service.error("ERROR");
        await this.service.success("모듈 삭제 성공!");
        await this.load(this.search.page);
    }

    public async importModule() {
        const files = await this.service.file.select({ accept: ".zip" });
        if (files.length === 0) return;
        const fd = new FormData();
        fd.append("file[]", files[0]);
        await this.service.file.upload(wiz.url("import_module"), fd);
        await this.service.success("import 성공!");
        await this.load();
    }
}

export default PortalKeycloudAdminModulesListComponent;