import '@angular/compiler';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalKeycloudExternalRpCredentialsComponent } from './portal.keycloud.external.rp.credentials/portal.keycloud.external.rp.credentials.component';
import { PortalKeycloudExternalSpComponent } from './portal.keycloud.external.sp/portal.keycloud.external.sp.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAdminSessionComponent } from './page.admin.session/page.admin.session.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PageAdminAgreementComponent } from './page.admin.agreement/page.admin.agreement.component';
import { PageAdminUsersRequestComponent } from './page.admin.users.request/page.admin.users.request.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalKeycloudExternalRpLogComponent } from './portal.keycloud.external.rp.log/portal.keycloud.external.rp.log.component';
import { PageAdminCustomComponent } from './page.admin.custom/page.admin.custom.component';
import { PageRegisterComponent } from './page.register/page.register.component';
import { PageAdminModulesprocComponent } from './page.admin.modulesproc/page.admin.modulesproc.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PageHistoryComponent } from './page.history/page.history.component';
import { PortalKeycloudAdminInternalOidcComponent } from './portal.keycloud.admin.internal.oidc/portal.keycloud.admin.internal.oidc.component';
import { ComponentLangComponent } from './component.lang/component.lang.component';
import { PageAdminExternalItemComponent } from './page.admin.external.item/page.admin.external.item.component';
import { PageMgmtComponent } from './page.mgmt/page.mgmt.component';
import { PortalKeycloudExternalRpTokenComponent } from './portal.keycloud.external.rp.token/portal.keycloud.external.rp.token.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalKeycloudAdminAttributesListComponent } from './portal.keycloud.admin.attributes.list/portal.keycloud.admin.attributes.list.component';
import { PortalKeycloudAdminModulesEditComponent } from './portal.keycloud.admin.modules.edit/portal.keycloud.admin.modules.edit.component';
import { PagePrivacyComponent } from './page.privacy/page.privacy.component';
import { PageAdminModulesComponent } from './page.admin.modules/page.admin.modules.component';
import { PageAdminDiscoveryComponent } from './page.admin.discovery/page.admin.discovery.component';
import { PageAdminInternalComponent } from './page.admin.internal/page.admin.internal.component';
import { PortalKeycloudExternalIdpComponent } from './portal.keycloud.external.idp/portal.keycloud.external.idp.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PageDeviceComponent } from './page.device/page.device.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageAgreementComponent } from './page.agreement/page.agreement.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalKeycloudExternalOpInfoComponent } from './portal.keycloud.external.op.info/portal.keycloud.external.op.info.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalKeycloudHistoryAgreementComponent } from './portal.keycloud.history.agreement/portal.keycloud.history.agreement.component';
import { PageLinkPasswordComponent } from './page.link.password/page.link.password.component';
import { PageAdminLogPageComponent } from './page.admin.log.page/page.admin.log.page.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalKeycloudAdminInternalSamlComponent } from './portal.keycloud.admin.internal.saml/portal.keycloud.admin.internal.saml.component';
import { ComponentDotBlinkComponent } from './component.dot.blink/component.dot.blink.component';
import { PortalKeycloudExternalAttributemapComponent } from './portal.keycloud.external.attributemap/portal.keycloud.external.attributemap.component';
import { PageMgmtItemComponent } from './page.mgmt.item/page.mgmt.item.component';
import { PortalKeycloudHistoryConsentComponent } from './portal.keycloud.history.consent/portal.keycloud.history.consent.component';
import { PortalKeycloudAdminDiscoveryComponent } from './portal.keycloud.admin.discovery/portal.keycloud.admin.discovery.component';
import { PortalKeycloudAdminAttributesScopesComponent } from './portal.keycloud.admin.attributes.scopes/portal.keycloud.admin.attributes.scopes.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAdminAttributesComponent } from './page.admin.attributes/page.admin.attributes.component';
import { PageAdminExternalComponent } from './page.admin.external/page.admin.external.component';
import { PortalKeycloudExternalRpOwnersComponent } from './portal.keycloud.external.rp.owners/portal.keycloud.external.rp.owners.component';
import { PortalKeycloudExternalSamlIdpFilterComponent } from './portal.keycloud.external.saml.idp.filter/portal.keycloud.external.saml.idp.filter.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalKeycloudExternalRpScopesComponent } from './portal.keycloud.external.rp.scopes/portal.keycloud.external.rp.scopes.component';
import { PortalKeycloudExternalRpInfoComponent } from './portal.keycloud.external.rp.info/portal.keycloud.external.rp.info.component';
import { PortalKeycloudAdminModulesListComponent } from './portal.keycloud.admin.modules.list/portal.keycloud.admin.modules.list.component';
import { PortalKeycloudAdminAttributesSamlattrComponent } from './portal.keycloud.admin.attributes.samlattr/portal.keycloud.admin.attributes.samlattr.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgSelectModule } from '@ng-select/ng-select';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AppComponent,
        PortalSeasonPagenationComponent,
        PortalSeasonLoadingHexaComponent,
        PortalKeycloudExternalRpCredentialsComponent,
        PortalKeycloudExternalSpComponent,
        PortalSeasonAlertComponent,
        PageAdminSessionComponent,
        ComponentLoadingComponent,
        PortalSeasonLoadingComponent,
        PageAdminAgreementComponent,
        PageAdminUsersRequestComponent,
        LayoutEmptyComponent,
        PortalKeycloudExternalRpLogComponent,
        PageAdminCustomComponent,
        PageRegisterComponent,
        PageAdminModulesprocComponent,
        PortalSeasonTabComponent,
        PortalSeasonUiDropdownComponent,
        PageHistoryComponent,
        PortalKeycloudAdminInternalOidcComponent,
        ComponentLangComponent,
        PageAdminExternalItemComponent,
        PageMgmtComponent,
        PortalKeycloudExternalRpTokenComponent,
        PageMypageComponent,
        PortalKeycloudAdminAttributesListComponent,
        PortalKeycloudAdminModulesEditComponent,
        PagePrivacyComponent,
        PageAdminModulesComponent,
        PageAdminDiscoveryComponent,
        PageAdminInternalComponent,
        PortalKeycloudExternalIdpComponent,
        ComponentQuestionComponent,
        PageDeviceComponent,
        PortalSeasonStatusbarComponent,
        PageAgreementComponent,
        LayoutAdminComponent,
        PortalKeycloudExternalOpInfoComponent,
        PageAdminUsersComponent,
        PortalKeycloudHistoryAgreementComponent,
        PageLinkPasswordComponent,
        PageAdminLogPageComponent,
        PageAuthenticateComponent,
        PortalSeasonViewerTreeComponent,
        PortalKeycloudAdminInternalSamlComponent,
        ComponentDotBlinkComponent,
        PortalKeycloudExternalAttributemapComponent,
        PageMgmtItemComponent,
        PortalKeycloudHistoryConsentComponent,
        PortalKeycloudAdminDiscoveryComponent,
        PortalKeycloudAdminAttributesScopesComponent,
        PortalSeasonLoadingSeasonComponent,
        PageAdminAttributesComponent,
        PageAdminExternalComponent,
        PortalKeycloudExternalRpOwnersComponent,
        PortalKeycloudExternalSamlIdpFilterComponent,
        ComponentPreComponent,
        ComponentNavComponent,
        ComponentNavAdminComponent,
        PortalKeycloudExternalRpScopesComponent,
        PortalKeycloudExternalRpInfoComponent,
        PortalKeycloudAdminModulesListComponent,
        PortalKeycloudAdminAttributesSamlattrComponent,
        LayoutNavbarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        HttpClientModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'ko',
        }),
        
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}