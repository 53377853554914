import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.rp.token');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-rp-token',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.rp.token/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.align-items-normal {
  align-items: normal;
}

.new-scope-input {
  width: 150px;
  vertical-align: middle;
  display: inline-block;
}

.mr-minus {
  margin-right: -5px;
}

.new-claim-selet {
  width: 120px;
  display: inline-block;
}`],
})
export class PortalKeycloudExternalRpTokenComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        this.scopes = {};
        if (!this.info.src.id) return this.back();
        await this.load();
        this.loaded = true;
        await this.service.render();
    }

    public mode = "access_token";
    public changeMode(mode) {
        this.mode = mode;
        this.load();
    }

    public list = [];
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: 1,
        start: 1,
    };

    public async load(page = 1) {
        this.search.page = page;
        this.list = [];
        await this.service.render();
        const body = this.service.copy(this.search);
        body.client_id = this.info.src.client_id;
        body.mode = this.mode;
        const { code, data } = await wiz.call("load", body);
        if (code != 200) return;
        this.list = data;
        let { rows, lastpage } = data;
        const dump = 20;
        const startpage = Math.floor((page - 1) / dump) * dump + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.render();
    }

    public async revoke(session_id) {
        const res = await this.service.alert.show({
            title: "Revoke Token",
            message: "정말 이 토큰을 비활성화하시겠습니까?",
            status: "error",
            actionBtn: "error",
            action: "revoke",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            session_id,
            token_type: this.mode,
        };
        const { code } = await wiz.call("revoke", body);
        if (code !== 200) return await this.service.error("토큰 비활성화 실패!");
        await this.service.success("토큰 비활성화 성공!");
        await this.load();
    }

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public back() {
        this.service.href(this._url(`/external/oidc/rp`));
    }
}

export default PortalKeycloudExternalRpTokenComponent;