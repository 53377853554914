import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.attributes.list');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-attributes-list',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.attributes.list/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.w-35 {
  width: 35% !important;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-left {
  justify-self: left;
}`],
})
export class PortalKeycloudAdminAttributesListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public list: any = [];
    public newItem: any = null;
    public onlySingle = ["sub", "name", "given_name", "family_name", "middle_name", "nickname", "preferred_username", "profile", "picture", "website", "email", "email_verified", "gender", "birthdate", "zoneinfo", "locale", "phone_number", "phone_number_verified", "address", "updated_at"];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public types = ["common", "profile", "system"];
    public typeMap = {
        system: "Admin Controlled",
        profile: "User Manageable",
        common: "Mapping Only",
    }

    public isDisabled(key) {
        return ['email', 'preferred_username', 'sub', 'firstName', 'lastName'].includes(key);
    }

    public isSystemDefault(key) {
        return ['preferred_username', 'sub'].includes(key);
    }

    public isOnlySingle() {
        return this.modal.mode === "edit" && this.onlySingle.includes(this.modal.data.key);
    }

    public modal = {
        mode: null,
        data: null,
    };

    public closeModal() {
        this.modal = {
            mode: null,
            data: null,
        };
        this.service.render();
    }

    public defaultOption() {
        return {
            maxlength: 255,
            multiple: false,
            type: "common",
            value_type: "text",
            oidc: {
                access_token: false,
                id_token: true,
                userinfo: true,
            },
            default_value: null,
        };
    }

    public showModal(mode, data = this.defaultOption()) {
        this.modal.mode = mode;
        this.modal.data = this.service.copy(data);
        if (mode === "create") {
            this.modal.data.code = `# generate 함수 미작성 시 사용자관리 화면에서 각 사용자별로 직접 값을 수동 지정할 수 있습니다.
# config: key, maxlength, multiple, default_value, value_type(text/number/boolean)
# attributes: user attributes (keycloud attributes)
# user_id: user's ID in userdb
# def generate(wiz, config, attributes, user_id):
#     if config["multiple"]: return ["test"]
#     return "test"
`;
        }
        this.service.render();
    }

    public async remove(item: any) {
        if (this.isDisabled(item.key)) return;
        const res = await this.service.alert.show({
            title: "DELETE",
            message: `정말 "${item.key}" 속성을 삭제하시겠습니까?`,
            action: "delete",
            cancel: "cancel",
        });
        if (!res) return;
        await wiz.call("delete", item);
        this.load(this.search.page);
    }

    public async update() {
        const body = this.service.copy(this.modal.data);
        if (body.key.replace(/\s/g, "").length === 0) return await this.service.error("name을 반드시 입력해주세요.");
        if (!/^[A-Za-z0-9_]+$/.test(body.key)) return await this.service.error("name은 알파벳, _, 숫자로 이루어져야 합니다.");
        switch (body.value_type) {
            case "text":
                if (body.default_value.length === 0) body.default_value = null;
                break;
            case "number":
                try {
                    body.default_value = +body.default_value;
                } catch { body.default_value = 0; }
                break;
            case "boolean":
                if (body.default_value === null) body.default_value = false;
                if (body.default_value) body.default_value = "true";
                else body.default_value = "false";
                break;
            default:
                body.default_value = null;
                break;
        }

        await this.service.loading.show();
        const { code, data } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("저장 실패!");
        await this.service.success("저장 성공!");
        this.closeModal();
        await this.load();
    }

    // monaco editor
    public monacoopts: any = {
        value: '',
        language: 'python',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        const el = document.querySelector("nu-monaco-editor");
        el.style.height = "300px";
        el.querySelector(".monaco-editor").style.height = "300px";
    }
}

export default PortalKeycloudAdminAttributesListComponent;